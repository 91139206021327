import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/react";
import {grayBottomBorder, navLink} from "./header.module.css";

const Header = ({ siteTitle }) => (
	<header css={css``}>
		<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>
		
        <nav className={`navbar mb-2 ${grayBottomBorder}`}>
			<div className="px-2 mx-2 navbar-start">
                <Link to="/" className="text-2xl font-bold">{siteTitle}</Link>
            </div>
			
            <div className="hidden px-2 mx-2 navbar-end lg:flex" id="navbarNavAltMarkup">
                <div className="flex items-stretch">
                    <Link to="/blogs/computer-vision" className={`${navLink} link link-hover`}>
                        Computer Vision
                    </Link>
                    <Link to="/blogs/deep-learning" className={`${navLink} link link-hover`}>
                        Deep Learning
                    </Link>
                </div>
			</div>
		</nav>
	</header>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
