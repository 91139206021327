import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "./../header"
import { pageCommons, grayTopBorder } from "./layout.module.css";

const Layout = ({ children }) => {
    const data = useStaticQuery(query);
    
	return (
        <div className={`container md:mx-auto px-1 lg:w-3/4 xl:w-4/5 ${pageCommons}`}>
				<Header siteTitle={data.site.siteMetadata.title} />
				<div>
					<main>
                        {children}
                    </main>
					
                    <footer className={`${grayTopBorder} p-10 footer`}>
						<div>
                            <p>Copyright &copy; {new Date().getFullYear()} - All rights reserved</p>
                            <p className="text-center">Ara Intelligence</p>
                        </div>
                        <div>
                            <span className="footer-title">Social</span>
                            <div className="grid grid-flow-col gap-4">
                                <a href="https://twitter.com/iceo_aj">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                                        <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
                                        </svg>
                                </a> 
                                {/* <a href="https://www.instagram.com/araintelligence/">
                                    <StaticImage 
                                        src="./../../images/instagram.svg" 
                                        alt="Instagram Icon"
                                        objectFit="cover"
                                        className="" 
                                        width={24}
                                        height={24} />
                                </a>  */}
                                {/* <a> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current">
                                    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                                    
                                    </svg>
                                </a> */}
                            </div>
                        </div>
					</footer>
				</div>
        </div>
    )
}

const query = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
}
`

export default Layout;
