import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import favicon16 from "../images/favicon-16x16.png"
import favicon32 from "../images/favicon-32x32.png"
import favicon from "../images/favicon.ico"

function SEO({ description, lang, meta, keywords, title, image }) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				const metaDescription =
					description || data.site.siteMetadata.description;
				const seoImage = image || "";
				return (
					<Helmet
						htmlAttributes={{
							lang,
						}}
						title={title}
						titleTemplate={`%s | ${data.site.siteMetadata.title}`}
						link={[
							{ rel: 'icon', type: 'image/png', sizes: "16x16", href: `${favicon16}` },
							{ rel: 'icon', type: 'image/png', sizes: "32x32", href: `${favicon32}` },
							{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
						]}
						meta={[
							{
								name: `description`,
								content: metaDescription,
							},
							{
								property: `og:title`,
								content: title,
							},
							{
								property: `og:description`,
								content: metaDescription,
							},
							{
								property: 'og:image',
								content: seoImage
							},
							{
								property: `og:type`,
								content: `website`,
							},
							{
								name: `twitter:card`,
								content: `summary`,
							},
							{
								name: `twitter:creator`,
								content: data.site.siteMetadata.twitterHandle,
							},
							{
								name: `twitter:title`,
								content: title,
							},
							{
								name: `twitter:description`,
								content: metaDescription,
							},
							{
								property: 'twitter:image',
								content: seoImage
							},
							{
								name: 'google-site-verification',
								content: 'v7JYui91IN193FB5BEiieCq0szu68UkifymAA6V1TtE'
							},
							{
								name: 'image',
								content: seoImage
							}
						]
							.concat(
								keywords.length > 0
									? {
											name: `keywords`,
											content: keywords.join(`, `),
										}
									: []
							)
							.concat(meta)}
					/>
				)
			}}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				description
				author
				twitterHandle
			}
		}
	}
`
